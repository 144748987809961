import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './redux/configStore';
import App from './App';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dashboardheader from './Dashboard-Component/dashboardheader';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>

      <App />
      {/* < Dashboardheader /> */}
    </Provider>
  </BrowserRouter>,
);
